const ValuesActionTypes = {
    // FETCH
    FETCH_VALUES_START: 'FETCH_VALUES_START',
    FETCH_VALUES_SUCCESS: 'FETCH_VALUES_SUCCESS',
    FETCH_VALUES_FAILURE: 'FETCH_VALUES_FAILURE',
    // UPDATE
    UPDATE_VALUES_START: 'UPDATE_VALUES_START',
    UPDATE_VALUES_SUCCESS: 'UPDATE_VALUES_SUCCESS',
    UPDATE_VALUES_FAILURE: 'UPDATE_VALUES_FAILURE',
    // DELETE
    DELETE_VALUES_START: 'DELETE_VALUES_START',
    DELETE_VALUES_SUCCESS: 'DELETE_VALUES_SUCCESS',
    DELETE_VALUES_FAILURE: 'DELETE_VALUES_FAILURE',
    // CREATE
    CREATE_VALUES_START: 'CREATE_VALUES_START',
    CREATE_VALUES_SUCCESS: 'CREATE_VALUES_SUCCESS',
    CREATE_VALUES_FAILURE: 'CREATE_VALUES_FAILURE'
}

export default ValuesActionTypes;