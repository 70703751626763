const ProjectActionTypes = {
    // FETCH
    FETCH_PROJECTS_START: 'FETCH_PROJECTS_START',
    FETCH_PROJECTS_SUCCESS: 'FETCH_PROJECTS_SUCCESS',
    FETCH_PROJECTS_FAILURE: 'FETCH_PROJECTS_FAILURE',
    // UPDATE
    UPDATE_PROJECT_START: 'UPDATE_PROJECT_START',
    UPDATE_PROJECT_SUCCESS: 'UPDATE_PROJECT_SUCCESS',
    UPDATE_PROJECT_FAILURE: 'UPDATE_PROJECT_FAILURE',
    // DELETE
    DELETE_PROJECT_START: 'DELETE_PROJECT_START',
    DELETE_PROJECT_SUCCESS: 'DELETE_PROJECT_SUCCESS',
    DELETE_PROJECT_FAILURE: 'DELETE_PROJECT_FAILURE',
    // CREATE
    CREATE_PROJECT_START: 'CREATE_PROJECT_START',
    CREATE_PROJECT_SUCCESS: 'CREATE_PROJECT_SUCCESS',
    CREATE_PROJECT_FAILURE: 'CREATE_PROJECT_FAILURE'
}

export default ProjectActionTypes;