const HomeActionTypes = {
    // FETCH
    FETCH_HOME_START: 'FETCH_HOME_START',
    FETCH_HOME_SUCCESS: 'FETCH_HOME_SUCCESS',
    FETCH_HOME_FAILURE: 'FETCH_HOME_FAILURE',

    // UPDATE
    UPDATE_HOME_START: 'UPDATE_HOME_START',
    UPDATE_HOME_SUCCESS: 'UPDATE_HOME_SUCCESS',
    UPDATE_HOME_FAILURE: 'UPDATE_HOME_FAILURE',

    // EDIT MODE
    CHANGE_EDIT_MODE: 'CHANGE_EDIT_MODE',

}

export default HomeActionTypes;